import type { Sex } from "~/types/onboarding/registrationParams";

export function useEnergyGoal() {
  const { getAge } = useAge();
  const { goalKgPerWeek } = useAppConfig();

  function getWeightChangePerWeek(startWeight: number, weightGoal: number) {
    if (startWeight !== weightGoal) {
      if (startWeight > weightGoal) {
        return -goalKgPerWeek;
      } else {
        return goalKgPerWeek;
      }
    }
    return 0;
  }

  function getEnergyGoal(sex: Sex, startWeight: number, weightGoal: number, height: number, dateOfBirth: string) {
    const weightChangePerWeek = getWeightChangePerWeek(startWeight, weightGoal);
    const age = getAge(dateOfBirth);
    const bmr = getBmr(sex, startWeight, height, age);
    let a = 1;
    let b = 1;
    if (startWeight !== weightGoal) {
      a = (startWeight - weightGoal) * 750;
      b = (startWeight - weightGoal) / weightChangePerWeek;
    }
    return Math.round(bmr * getSexDefaultPal(sex) + a / b);
  }

  function getBmr(sex: Sex, startWeight: number, height: number, age: number) {
    const bmrFactor = sex === "male" ? 5 : -161;
    return 10 * startWeight + 6.25 * height - 5 * age + bmrFactor;
  }

  function getSexDefaultPal(sex: Sex) {
    const palSex = sex === "male" ? 1.57 : 1.52;
    const palSleeping = 0.95;
    return (palSex * 16 + palSleeping * 8) / 24;
  }

  return {
    getWeightChangePerWeek,
    getEnergyGoal,
  };
}
